<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>签约合同</h3>
            </div>
            <User></User>
        </div>
        <div class="cont-box">

            <div class="invoice-upload">
                <div>
                    <!-- 表格 -->
                    <div class="upload-header-tabble">
                        <h4>签约列表</h4>
                        <el-table :data="tableData" style="width: 100%" title="签约列表">
                            <el-table-column type="index" label="序号" width="60">
                            </el-table-column>
                            <el-table-column prop="faAgreement.agreeName" label="合约名称" width="400"> </el-table-column>

                            <el-table-column prop="faAgreement.agreeType" label="合约类型" width="150"></el-table-column>
                            <el-table-column prop="faAgreement.number" label="有效期起止" width="200">
                                <template slot-scope="scope">
                                    <span>{{scope.row.faAgreement.startTime}}</span><span> - </span><span>{{scope.row.faAgreement.endTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="faAgreement.status" label="合约状态" width="150">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.faAgreement.status == 0">待签约</p>
                                    <p v-if="scope.row.faAgreement.status == 1">已签约</p>
                                    <p v-if="scope.row.faAgreement.status == 2">已失效</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="faAgreement.statuc" label="操作" width="150">
                                <template slot-scope="scope">
                                    <el-button  type="text" size="small" @click="OpenSee(`${scope.row.faAgreement.agreeFile}`)">查看</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="faAgreement.agreeName" label="附属合同" >
                                <template slot-scope="scope">
                                    <!-- subsidiary -->
                                   <!-- <span></span> -->
                                   <div class="subsidiaryBox" v-for="(item,index) in scope.row.faAddonAgreements">
                                    <span @click="OpenSee(`${item.agreementContent}`)">{{item.addonName}}</span>
                                   </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total="tableDataCount"
                            @current-change="currentChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import User from "./../../components/user.vue"

export default {
    components:{
        User
    },
    data() {
        return {
            value: new Date(),
            // 表格数据
            tableData: [],
            tableDataCount: 0,
            code: 1,
        }
    },
    created(){
        this.PostmyContert();//合同列表
    },
    methods: {
        // 合同列表
        PostmyContert(){
            let userID = localStorage.getItem('userID');
            this.$api.myContert({
                userId:userID,
                pagecode:this.code,
                pagesize:10,
            }).then(res=>{
                res.data.forEach(item => {
                    // 开始时间
                    item.faAgreement.startTime = this.$formDate(item.faAgreement.startTime,'d','/',1000)
                    // 结束时间
                    item.faAgreement.endTime = this.$formDate(item.faAgreement.endTime,'d','/',1000)
                });
                this.tableData = res.data;
                console.log(this.tableData, '=-=-=-=-=-=-=-=-=');
                this.tableDataCount = res.count;
            })
        },
        // 分页
        currentChange(e) {
            this.code = e
            this.PostmyContert();
        },
        // 查看
        OpenSee(url){
            this.$see(url)
        }
    }
}
</script>
<style scoped>
.content {

    min-height: 100vh;
    background-color: #eee;
}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cont-box {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
}

.upload-header-tabble h4 {
    font-size: 1em;
    color: #333333;
    margin-bottom: 1em;
}

.pagination {
    margin-top: 1em;
    text-align: center;
}
.subsidiaryStyle{
    background-color: rgba(0, 0, 0, 0);
    border: none;
}
.subsidiaryStyle::after{
    border: none;
}
.subsidiaryBox{
    width: auto;
    /* display: flex; */
}
.subsidiaryBox span{
    min-width: 20px;
    padding: 0 5px;
    color: #409EFF;
}
</style>